import React, {useEffect, useState} from "react";
import axios from "axios";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {Meeting} from "../model/Responses";
import {DateRange} from "../hooks/useGraphFilters";

export const AdminButton = ({from, to}: DateRange) => {
    const [adminRights, setAdminRights] = useState(false);

    useEffect(() => {
        axios.get<boolean>('/admin').then(response => setAdminRights(response.data))
    }, []);

    return (adminRights ?
            <Button danger onClick={() => {
                axios.get<Meeting[]>('/meetings/update',
                    {
                        params: {
                            from: from.toISOString().split('T')[0],
                            to: to.toISOString().split('T')[0]
                        }
                    });
            }}>
                Update
            </Button>
            : null
    )
}