import {useCallback, useReducer} from "react";
import {LinkMetaType, linkMetaTypesWithLabels, NodeType, nodeTypesWithLabels} from "../model/Model";
import {useDebounced} from "./useDebounced";

// TODO: move into a more reasonable place
export type DateRange = {
    readonly from: Date,
    readonly to: Date
}

type GraphFiltersAction =
    | {
    readonly type: 'node-type',
    readonly nodeType: NodeType
}
    | {
    readonly type: 'link-types',
    readonly metaTypes: LinkMetaType[]
}
    | {
    readonly type: 'dates',
    readonly dates: DateRange
}

interface GraphFiltersState {
    readonly visibleNodeTypes: Set<NodeType>,
    readonly visibleLinkMeta: Set<LinkMetaType>,
    readonly dates: DateRange
}

type GraphFiltersStateReducer = (prevState: GraphFiltersState, action: GraphFiltersAction) => GraphFiltersState

const graphFiltersStateReducer: GraphFiltersStateReducer = (state, action) => {
    switch (action.type) {
        case 'node-type': {
            let visibleTypes = new Set(state.visibleNodeTypes);
            if (!visibleTypes.delete(action.nodeType)) {
                visibleTypes.add(action.nodeType);
            }
            return {
                ...state,
                visibleNodeTypes: visibleTypes
            };
        }

        case 'dates': {
            return {
                ...state,
                dates: action.dates,
            };
        }

        case 'link-types': {
            let visibleLinkMeta = new Set(state.visibleLinkMeta);
            if (visibleLinkMeta.delete(action.metaTypes[0])) {
                action.metaTypes.forEach(meta => visibleLinkMeta.delete(meta));
            } else {
                action.metaTypes.forEach(meta => visibleLinkMeta.add(meta));
            }
            return {
                ...state,
                visibleLinkMeta
            };
        }

        default:
            throw new Error('Unsupported App action');
    }
}

export interface GraphFilters extends GraphFiltersState {
    readonly participantsThreshold: number
}

export const defaultFilters = () => ({
    visibleNodeTypes: new Set(nodeTypesWithLabels.map(it => it.type)),
    visibleLinkMeta: new Set(linkMetaTypesWithLabels.map(it => it.types).flat()),
    // { from: new Date(new Date().setDate(new Date().getDate() - 7)), to: new Date()}
    dates: {from: new Date("2023-09-01"), to: new Date("2023-09-14")}
});

export interface GraphFiltersWithSetters extends GraphFilters {
    readonly pendingParticipantsThreshold: number,
    readonly nodeTypeSwitch: (nodeType: NodeType) => void,
    readonly linkTypeSwitch: (metaTypes: LinkMetaType[]) => void,
    readonly changeDates: (dates: DateRange) => void,
    readonly changeParticipantsThreshold: (threshold: number) => void
}

export function useGraphFilters(): GraphFiltersWithSetters {
    const [filters, filtersDispatch] = useReducer(graphFiltersStateReducer, null, defaultFilters);

    const [participantsThreshold, pendingParticipantsThreshold, changeParticipantsThreshold] = useDebounced(10, 500);

    const nodeTypeSwitch = useCallback((nodeType: NodeType) => filtersDispatch({
        type: 'node-type',
        nodeType
    }), [filtersDispatch]);
    const linkTypeSwitch = useCallback((metaTypes: LinkMetaType[]) => filtersDispatch({
        type: 'link-types',
        metaTypes
    }), [filtersDispatch]);
    const changeDates = useCallback(((dates: DateRange) => filtersDispatch({type: 'dates', dates})), [filtersDispatch]);

    return {
        ...filters,
        participantsThreshold,
        pendingParticipantsThreshold,
        nodeTypeSwitch,
        linkTypeSwitch,
        changeDates,
        changeParticipantsThreshold
    };
}
