import './App.css';
import '@jetbrains/ring-ui/dist/style.css';

import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import axios from "axios";
import LoaderScreen from "@jetbrains/ring-ui/dist/loader-screen/loader-screen";
import {Tray} from "@jetbrains/ring-ui/dist/header/header";
import Button from "@jetbrains/ring-ui/dist/button/button";
import ButtonSet from "@jetbrains/ring-ui/dist/button-set/button-set";
import updateIcon from '@jetbrains/icons/update';
import {AppHeader} from "./components/AppHeader";
import {ForceGraph} from "./components/ForceGraph";
import {AppFooter} from "./components/AppFooter";
import {NodeTooltip} from "./components/NodeTooltip";
import {emptyGraph} from "./model/Tranformations";
import {DataGraph, Meeting} from "./model/Responses";
import useFetchData from "./hooks/useFetchData";
import {graphStateReducer, GraphStateReducer} from "./components/utils/GraphState";
import {Settings} from "./components/Settings";
import {Filters} from "./components/Filters";
import {LinkTooltip} from "./components/LinkTooltip";
import {AdminButton} from "./components/AdminButton";
import {Help} from "./components/Help";
import {useUiSettings} from "./hooks/useUiSettings";
import {defaultFilters, GraphFilters, useGraphFilters} from "./hooks/useGraphFilters";
import {Actions} from "./components/Actions";

axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_URL}/api`;
axios.defaults.withCredentials = true;

export const App = ({logout}: {
    logout: () => void;
}) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const {loading: graphLoading, load: graphLoad, ready, data} = useFetchData<DataGraph>(`/graph`, "Space data");
    const uiSettings = useUiSettings()
    const fullFilters = useGraphFilters();
    // filters have setters and change too frequently => separate graph filters with only relevant fields
    const [filters, updateFilters] = useState<GraphFilters>(fullFilters)
    const [graphState, graphDispatch] = useReducer<GraphStateReducer>(graphStateReducer, {
        dataGraph: emptyGraph(),
        visibleGraph: emptyGraph(),
        meetings: [],
        meetingsMap: new Map<string, Meeting>()
    });

    // function to update the visible graph ~ renderGraph
    const updateVisibleGraph = useCallback(() => graphDispatch({type: 'update-visible', filters}), [filters]);
    // apply filters when they change
    useEffect(() => updateVisibleGraph(), [updateVisibleGraph]);

    useEffect(() => {
        updateFilters({
            dates: fullFilters.dates,
            participantsThreshold: fullFilters.participantsThreshold,
            visibleLinkMeta: fullFilters.visibleLinkMeta,
            visibleNodeTypes: fullFilters.visibleNodeTypes
        });
    }, [fullFilters.dates, fullFilters.participantsThreshold, fullFilters.visibleLinkMeta, fullFilters.visibleNodeTypes]);

    // load graph asap
    useEffect(() => {
        const controller = new AbortController();
        graphLoad(controller.signal).then(graphData => {
            if (graphData)
                graphDispatch({type: 'data', graphData, filters: {...defaultFilters(), participantsThreshold: 10}});
        })
        return () => controller.abort();
    }, [graphLoad]);

    return (
        <>
            <AppHeader graph={graphState.dataGraph} onApplySuggestion={(nodeSuggestion) => {
                // TODO: make an action in graph state
                nodeSuggestion.node.visible = true;
                updateVisibleGraph();
            }}>
                {/* FIXME: Tray and ButtonSet are here only to position everything correctly*/}
                <Tray className="tray">
                    <ButtonSet>
                        <Help/>
                        <Button title="Force re-render" icon={updateIcon} onClick={() => updateVisibleGraph()}/>
                        <Settings uiSettings={uiSettings}
                                  onMeetingsLoaded={(meetingsData) => graphDispatch({
                                      type: 'meetings',
                                      meetingsData,
                                      filters
                                  })}/>
                        <Filters filters={fullFilters}/>
                        <Actions onHideIsolated={() => graphDispatch({type: 'hide-isolated', filters: fullFilters})}
                                 onHideNodeType={(nodeType) => graphDispatch({type: 'hide-type', nodeType, filters})}
                                 graph={graphState.dataGraph}
                                 onHideEverything={() => graphDispatch({type: 'hide-everything', filters})}
                                 onConnect={(firstId, secondId) => graphDispatch({
                                     type: "connect",
                                     firstId,
                                     secondId,
                                     filters
                                 })}/>

                        <AdminButton {...fullFilters.dates}/>
                        <Button onClick={logout}>Log Out</Button>
                    </ButtonSet>
                </Tray>
            </AppHeader>
            <div id="main">
                {graphLoading ? <LoaderScreen/> : null}
                <ForceGraph graph={graphState.visibleGraph} labelsEnabled={uiSettings.labelsEnabled}
                            linkTooltipSvgRef={svgRef}/>
            </div>
            {ready && graphState.visibleGraph.nodes.length === 0 && data ? <EmptyGraph/> : null}
            <AppFooter/>

            <NodeTooltip graph={graphState.dataGraph} show={uiSettings.nodeTooltipEnabled}
                         onMakeVisible={(nodeId) => graphDispatch({type: 'make-visible', nodeId, filters: filters})}
                         onHide={(nodeId) => graphDispatch({type: 'hide', nodeId, filters: filters})}
                         onHideNonAdjacent={(nodeId) => graphDispatch({
                             type: 'hide-non-adjacent',
                             nodeId,
                             filters: filters
                         })}/>
            <LinkTooltip show={uiSettings.linkTooltipEnabled} svgRef={svgRef}
                         nodesMap={graphState.dataGraph.nodesMap} meetingsMap={graphState.meetingsMap}/>
        </>
    );
}

const EmptyGraph = () => (<div id="empty">Nothing visible selected. <br/> Try removing filters or using search.</div>);
