import useAuth from "../hooks/useAuth";
import React from "react";
import {App} from "../App";
import frownIcon from "@jetbrains/icons/user-spinner";
import ErrorMessage from "@jetbrains/ring-ui/dist/error-message/error-message";

export const AuthedApp = () => {
    const {authed, logout, error} = useAuth();

    return (
        authed ?
            <App logout={logout}/>
            :
            <ErrorMessage
                icon={frownIcon}
                code="Not authorized in Space"
                message={error?.message ?? ""}
                description="Please try again soon."
            />
    );
}