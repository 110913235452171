import React, {PropsWithChildren} from "react";
import Header, {Logo} from "@jetbrains/ring-ui/dist/header/header";
import Theme from "@jetbrains/ring-ui/dist/global/theme";
// @ts-ignore
import jetbrainsLogo from '@jetbrains/logos/jetbrains/jetbrains-simple.js';
import {NodeSearchProps, NodeSearch} from "./NodeSearch";

export const AppHeader = (props: NodeSearchProps & PropsWithChildren) => (
    <Header theme={Theme.DARK} className='compactHeader'>
        <a title="JetBrains" href="https://jetbrains.team/">
            <Logo glyph={jetbrainsLogo} size={Logo.Size.Size48}/>
        </a>

        <NodeSearch {...props}/>

        {props.children}
    </Header>
);
