import {GraphFilters} from "../../hooks/useGraphFilters";
import {MeetingsData} from "../../hooks/useMeetings";
import {DataGraph, Meeting} from "../../model/Responses";
import {Graph, GraphNode, NodeType} from "../../model/Model";
import {mergeMeetingsIntoGraph, transformGraph} from "../../model/Tranformations";
import {buildVisibleGraph, isLinkVisible, makeAdjacentVisible} from "./Filtering";
import {connectNodes} from "../../graph/connectNodes";

type WithFilters = {
    readonly filters: GraphFilters
}

type GraphAction = WithFilters & (
    | {
    readonly type: 'data',
    readonly graphData: DataGraph
}
    | {
    readonly type: 'meetings',
    readonly meetingsData: MeetingsData
}
    | {
    readonly type: 'update-visible'
}
    | {
    readonly type: 'hide-isolated'
}
    | {
    readonly type: 'make-visible',
    readonly nodeId: string
}
    | {
    readonly type: 'hide',
    readonly nodeId: string
}
    | {
    readonly type: 'hide-type',
    readonly nodeType: NodeType
}
    | {
    readonly type: 'hide-non-adjacent',
    readonly nodeId: string
}
    | {
    readonly type: 'hide-everything'
}
    | {
    readonly type: 'connect',
    readonly firstId: string,
    readonly secondId: string
})

export type GraphState = {
    readonly dataGraph: Graph,
    readonly meetings: Meeting[],
    readonly meetingsMap: Map<string, Meeting>,
    readonly visibleGraph: Graph
}

export type GraphStateReducer = (prevState: GraphState, action: GraphAction) => GraphState

export const graphStateReducer: GraphStateReducer = (state, action) => {
    switch (action.type) {
        case 'data': {
            const dataGraph = transformGraph(action.graphData);
            const node = dataGraph.nodesMap.get(action.graphData.userId);
            if (!node) throw new Error(`User ${action.graphData.userId} does not exist?!!`);
            makeAdjacentVisible(node, action.filters, state.meetingsMap);
            const visibleGraph = buildVisibleGraph(dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                dataGraph,
                visibleGraph
            };
        }

        case 'meetings':
            const newDataGraph = mergeMeetingsIntoGraph(state.dataGraph, action.meetingsData.meetingLinksData);
            const meetingsMap = new Map<string, Meeting>();
            action.meetingsData.meetingsData.forEach(meeting => meetingsMap.set(meeting.id, meeting));
            const visibleGraph = buildVisibleGraph(newDataGraph, action.filters, meetingsMap);
            return {
                ...state,
                dataGraph: newDataGraph,
                visibleGraph,
                meetings: action.meetingsData.meetingsData,
                meetingsMap
            };

        case 'update-visible': {
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph
            };
        }

        case 'make-visible': {
            const node = state.dataGraph.nodesMap.get(action.nodeId);
            if (!node) return state;
            makeAdjacentVisible(node, action.filters, state.meetingsMap);
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph
            };
        }

        case "hide": {
            const node = state.dataGraph.nodesMap.get(action.nodeId);
            if (!node) return state;
            node.visible = false;
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph,
            };
        }

        case "hide-type": {
            state.dataGraph.nodes.forEach(node => {
                node.visible = node.visible && node.type !== action.nodeType;
            });
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph,
            };
        }

        case "hide-non-adjacent": {
            const node = state.dataGraph.nodesMap.get(action.nodeId);
            if (!node) return state;

            const adjacent = new Set<GraphNode>();
            node.adjacent.forEach(link => {
                adjacent.add(link.source);
                adjacent.add(link.target);
            });
            state.visibleGraph.nodes.forEach(node => {
                if (!adjacent.has(node)) {
                    node.visible = false;
                }
            })
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph
            };
        }

        case 'hide-isolated': {
            state.visibleGraph.nodes.forEach(node => {
                const visibleLinksCount = node.adjacent.filter(link =>
                    isLinkVisible(link, action.filters, state.meetingsMap) &&
                    link.source.visible && link.target.visible
                ).length
                if (visibleLinksCount === 0) {
                    node.visible = false;
                }
            });
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph
            };
        }

        case "hide-everything": {
            state.dataGraph.nodes.forEach(node => node.visible = false);
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph
            };
        }

        case "connect": {
            const firstNode = state.dataGraph.nodesMap.get(action.firstId);
            const secondNode = state.dataGraph.nodesMap.get(action.secondId);
            if (!firstNode || !secondNode) return state;
            connectNodes(firstNode, secondNode).forEach(link => {
                link.source.visible = true;
                link.target.visible = true;
            })
            const visibleGraph = buildVisibleGraph(state.dataGraph, action.filters, state.meetingsMap);
            return {
                ...state,
                visibleGraph
            };
        }

        default:
            throw new Error('Unsupported Graph action');
    }
}
