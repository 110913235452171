import React, {useState} from "react";
import Dropdown from "@jetbrains/ring-ui/dist/dropdown/dropdown";
import Button from "@jetbrains/ring-ui/dist/button/button";
import PopupMenu from "@jetbrains/ring-ui/dist/popup-menu/popup-menu";
import {ListProps} from "@jetbrains/ring-ui/dist/popup-menu/popup-menu";
import magicWandIcon from '@jetbrains/icons/magic-wand';
import {Graph, NodeType, nodeTypesWithLabels} from "../model/Model";
import {ConnectNodesDialog} from "./ConnectNodesDialog";

export interface ActionsProps {
    readonly graph: Graph
    readonly onHideNodeType: (nodeType: NodeType) => void,
    readonly onHideIsolated: () => void,
    readonly onHideEverything: () => void,
    readonly onConnect: (firstId: string, secondId: string) => void
}

export const Actions = (props: ActionsProps) => {
    const [showConnect, setShowConnect] = useState(false);

    return (<>
        <Dropdown anchor={<Button title="Settings" icon={magicWandIcon}/>}>
            <PopupMenu data={
                [
                    {rgItemType: ListProps.Type.SEPARATOR, description: 'Node types'},
                    ...nodeTypesWithLabels
                        .map(({type, label}) => ({
                            rgItemType: ListProps.Type.CUSTOM,
                            label,
                            template: <Button onClick={() => props.onHideNodeType(type)}>{`Hide ${label}`}</Button>
                        })),
                    {rgItemType: ListProps.Type.SEPARATOR, description: 'More'},
                    {
                        rgItemType: ListProps.Type.CUSTOM,
                        template: <Button onClick={props.onHideIsolated}>Hide isolated nodes</Button>
                    },
                    {
                        rgItemType: ListProps.Type.CUSTOM,
                        template: <Button onClick={props.onHideEverything}>Hide everything</Button>
                    },
                    {
                        rgItemType: ListProps.Type.CUSTOM,
                        template: <Button danger onClick={() => setShowConnect(true)}>Connect nodes</Button>
                    }
                ].map((item, index) => ({...item, key: index}))
            }/>
        </Dropdown>

        <ConnectNodesDialog show={showConnect} onClose={() => setShowConnect(false)} {...props}/>
    </>)
}
