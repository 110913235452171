import React from 'react';
import {createRoot} from "react-dom/client";
import './index.css';
import {ErrorBoundary} from "./components/ErrorBoundary";
import {AuthedApp} from "./components/AuthedApp";

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <AuthedApp/>
        </ErrorBoundary>
    </React.StrictMode>
);
