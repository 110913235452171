import {GraphLink, GraphNode} from "../model/Model";

export function connectNodes(nodeStart: GraphNode, nodeEnd: GraphNode): GraphLink[] {
    const fromMap = new Map<string, GraphLink>();
    const toVisit: GraphNode[] = [nodeStart];

    while (!fromMap.has(nodeEnd.id)) {
        const next = toVisit.shift()
        if (!next) return []; // cannot reach the target node
        step(next, fromMap, toVisit)
    }

    return buildPath(nodeStart, nodeEnd, fromMap);
}

function step(node: GraphNode, fromMap: Map<string, GraphLink>, toVisit: GraphNode[]) {
    node.adjacent.forEach(link => {
        [link.source, link.target].forEach(linkNode => {
            const inMap = fromMap.get(linkNode.id);
            if (inMap) return;
            fromMap.set(linkNode.id, link);
            toVisit.push(linkNode);
        })
    });
}

function buildPath(nodeStart: GraphNode, nodeEnd: GraphNode, fromMap: Map<string, GraphLink>): GraphLink[] {
    const result: GraphLink[] = []
    let currentNode = nodeEnd;

    while (currentNode !== nodeStart) {
        const link = fromMap.get(currentNode.id);
        if (!link) throw new Error('Impossible!');
        result.push(link);
        currentNode = stepBack(currentNode, link);
    }

    return result.reverse();
}

function stepBack(from: GraphNode, link: GraphLink): GraphNode {
    if (link.source === from) return link.target;
    return link.source;
}
