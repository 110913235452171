import {Graph, GraphLink, GraphNode, NodeType} from "./Model";
import {DataGraph, DataLink, DataNode} from "./Responses";

export const emptyGraph = () => ({nodes: [], links: [], nodesMap: new Map()});

function nodeRadius(node: DataNode) {
    const baseSize = 30;
    switch (node.type) {
        case NodeType.TEAM:
            return node.size > 0 ? baseSize * Math.sqrt(Math.sqrt(node.size)) : baseSize;
        case NodeType.USER:
            return baseSize * 1.5;
        default:
            return baseSize;
    }
}

function wrapNode(dataNode: DataNode): GraphNode {
    return {
        ...dataNode,
        x: 0,
        y: 0,
        visible: false,
        radius: nodeRadius(dataNode),
        adjacent: []
    };
}

export function transformLinks(links: Array<DataLink>, nodesMap: Map<string, GraphNode>) {
    let transformedLinks: Array<GraphLink> = [];
    links.forEach(link => {
        let nodeSource = nodesMap.get(link.source);
        let nodeTarget = nodesMap.get(link.target);
        if (nodeSource && nodeTarget) {
            const newLink = {source: nodeSource, target: nodeTarget, meta: link.meta};
            nodeSource.adjacent.push(newLink);
            nodeTarget.adjacent.push(newLink);
            transformedLinks.push(newLink);
        }
    });
    return transformedLinks;
}


export function transformGraph(graph: DataGraph): Graph {
    const result: Graph = emptyGraph();

    result.nodes = graph.nodes.map(node => wrapNode(node));
    result.nodes.forEach(node => result.nodesMap.set(node.id, node));
    result.links = transformLinks(graph.links, result.nodesMap);

    return result;
}

export function mergeMeetingsIntoGraph(graph: Graph, meetingLinks: DataLink[]) {
    if (meetingLinks.length === 0) return graph;

    const linksMap = new Map<string, GraphLink>();

    graph.links.forEach(link => {
        linksMap.set(link.source.id + link.target.id, link);
    });

    meetingLinks.forEach(dataLink => {
        const nodeSource = graph.nodesMap.get(dataLink.source);
        const nodeTarget = graph.nodesMap.get(dataLink.target);
        if (!nodeSource || !nodeTarget) return;
        const existingLink = linksMap.get(dataLink.source + dataLink.target);
        if (existingLink) {
            existingLink.meta = existingLink.meta.concat(dataLink.meta);
        } else {
            const newLink = {source: nodeSource, target: nodeTarget, meta: dataLink.meta};
            linksMap.set(dataLink.source + dataLink.target, newLink);
            nodeSource.adjacent.push(newLink);
            nodeTarget.adjacent.push(newLink);
        }
    });

    const newLinks = Array.from(linksMap.values());

    return {
        ...graph,
        links: newLinks
    };
}
