import {RefObject, useEffect, useState} from "react";


export const useSvgDimensions = (svgRef: RefObject<SVGSVGElement>) => {
    const [{
        width,
        height
    }, setDimensions] = useState({
        width: window.screen.availWidth,
        height: window.screen.availHeight
    });

    useEffect(() => {
        if (svgRef.current) {
            const observeTarget = svgRef.current;
            const resizeObserver = new ResizeObserver(entries => entries.forEach(entry => {
                setDimensions(entry.contentRect);
            }));
            resizeObserver.observe(observeTarget);
            return () => {
                resizeObserver.unobserve(observeTarget);
            };
        }
        return () => {}
    }, [svgRef]);

    return [width, height];
};