import {Graph, GraphLink, GraphNode, LinkMetaType} from "../../model/Model";
import {Meeting} from "../../model/Responses";
import {GraphFilters} from "../../hooks/useGraphFilters";

type MeetingsMap = Map<string, Meeting>

const isNodeVisible = (node: GraphNode, filters: GraphFilters) => node.visible && filters.visibleNodeTypes.has(node.type);

export const isLinkVisible = (link: GraphLink, filters: GraphFilters, meetingsMap: MeetingsMap) => {
    const thisVisibleMeta = link.meta.filter(meta => {
        if (!filters.visibleLinkMeta.has(meta.type)) return false;
        if (meta.type === LinkMetaType.MEETING) {
            const meeting = meetingsMap.get(meta.id);
            if (!meeting) return true;
            return meeting.participants <= filters.participantsThreshold &&
                new Date(meeting.start) <= filters.dates.to && new Date(meeting.end) >= filters.dates.from;
        }
        return true;
    })
    return thisVisibleMeta.length > 0;
};

// FIXME: should this actually take into account filters? Or maybe building the visible graph manages that?
export const makeAdjacentVisible = (node: GraphNode, filters: GraphFilters, meetingsMap: MeetingsMap) => {
    node.visible = true;
    node.adjacent.forEach(link => {
        const other = link.target.id !== node.id ? link.target : link.source;
        if (other.visible) return;
        const linkVisible = isLinkVisible(link, filters, meetingsMap);
        if (linkVisible) {
            other.visible = true;
        }
    });
}


export const buildVisibleGraph = (dataGraph: Graph, filters: GraphFilters, meetingsMap: MeetingsMap) => {
    const visibleNodes = dataGraph.nodes.filter(node => isNodeVisible(node, filters));
    const visibleLinks = dataGraph.links.filter(link =>
        isNodeVisible(link.source, filters) && isNodeVisible(link.target, filters)
        && isLinkVisible(link, filters, meetingsMap)
    );
    return {
        nodes: visibleNodes,
        links: visibleLinks,
        nodesMap: dataGraph.nodesMap
    }
}
