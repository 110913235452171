import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";

export function useDebounced<T>(initialValue: T, delay: number): [T,  T, Dispatch<SetStateAction<T>>] {
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
    const [nextValue, setNextValue] = useState<T>(initialValue);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(nextValue);
        }, delay)
        return () => {
            clearTimeout(timer)
        }
    }, [nextValue])

    return [debouncedValue, nextValue, setNextValue];
}