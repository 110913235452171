import {useCallback, useReducer} from "react";

type UiAction =
    | 'node-tooltip'
    | 'link-tooltip'
    | 'labels'

interface UiState {
    readonly nodeTooltipEnabled: boolean,
    readonly linkTooltipEnabled: boolean,
    readonly labelsEnabled: boolean
}

type UiStateReducer = (prevState: UiState, action: UiAction) => UiState

const uiStateReducer: UiStateReducer = (state, action) => {
    switch (action) {
        case 'node-tooltip':
            return {...state, nodeTooltipEnabled: !state.nodeTooltipEnabled};

        case "link-tooltip":
            return {...state, linkTooltipEnabled: !state.linkTooltipEnabled}

        case 'labels':
            return {...state, labelsEnabled: !state.labelsEnabled};

        default:
            throw new Error('Unsupported UI action');
    }
}

export interface UiSettings extends UiState{
    nodeTooltipSwitch: () => void,
    linkTooltipSwitch: () => void,
    labelsSwitch: () => void
}

export function useUiSettings(): UiSettings {
    const [uiState, uiDispatch] = useReducer<UiStateReducer>(uiStateReducer, {
        nodeTooltipEnabled: true,
        linkTooltipEnabled: true,
        labelsEnabled: true
    });

    // uiDispatch identity is guaranteed to not change, nut just to be sure wrapped in useCallback
    const nodeTooltipSwitch = useCallback(() => uiDispatch('node-tooltip'), [uiDispatch]);
    const linkTooltipSwitch = useCallback(() => uiDispatch('link-tooltip'), [uiDispatch]);
    const labelsSwitch = useCallback(() => uiDispatch('labels'), [uiDispatch]);

    return {
        ...uiState,
        nodeTooltipSwitch,
        linkTooltipSwitch,
        labelsSwitch
    }
}
