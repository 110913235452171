import React, {MutableRefObject, useCallback, useEffect, useState} from "react";
import Island, {Content, Header} from "@jetbrains/ring-ui/dist/island/island";
import List from "@jetbrains/ring-ui/dist/list/list";
import RingTooltip from '@jetbrains/ring-ui/dist/tooltip/tooltip'
import newWindow from '@jetbrains/icons/new-window';
import Icon from "@jetbrains/ring-ui/dist/icon/icon";
import Button from "@jetbrains/ring-ui/dist/button/button";
import pinIcon from "@jetbrains/icons/pin-filled";
import {GraphLink, GraphNode, LinkMetaType} from "../model/Model";
import {LinkMeta, Meeting} from "../model/Responses";

export interface LinkTooltipProps {
    readonly show: boolean,
    readonly svgRef: MutableRefObject<SVGSVGElement | null>,
    readonly nodesMap: Map<string, GraphNode>,
    readonly meetingsMap: Map<string, Meeting>
}

const meetingItem = (meeting: Meeting) => (
    {
        rgItemType: List.ListProps.Type.CUSTOM,
        template:
            <span>
                <RingTooltip title={`@ ${meeting.start}, ${meeting.participants} participants`}>
                    <Icon
                        glyph={newWindow}
                        color={Icon.Color.BLUE}
                        onClick={() => window.open(`https://jetbrains.team/meetings/${meeting.id}`)}
                    />
                    {/* FIXME */}
                    {" "}
                    {meeting.name}
                </RingTooltip>
            </span>
    }
)

const metaToItem = (meta: LinkMeta, meetingsMap: Map<string, Meeting>) => {
    switch (meta.type) {
        case LinkMetaType.MANAGER:
            return {label: "Management ->", rgItemType: List.ListProps.Type.ITEM};
        case LinkMetaType.REGANAM:
            return {label: "Management <-", rgItemType: List.ListProps.Type.ITEM};
        case LinkMetaType.MEETING:
            const meeting = meetingsMap.get(meta.id);
            if (meeting) return meetingItem(meeting);
            return {
                label: "Unknown meeting",
                href: `https://jetbrains.team/meetings/${meta.id}`,
                rgItemType: List.ListProps.Type.LINK
            };
        case LinkMetaType.PARENT:
        case LinkMetaType.MEMBER:
            return {label: "Hierarchy", rgItemType: List.ListProps.Type.ITEM};
        case LinkMetaType.LOCATED:
            return {label: "Located", rgItemType: List.ListProps.Type.ITEM};
        // case LinkMetaType.CODE_REVIEW:
        //     return {label: "Is not supported yet and should not happen", rgItemType: List.ListProps.Type.ITEM};
    }
}

type State = {
    readonly link: GraphLink | null,
    readonly fixed: boolean
}

export const LinkTooltip = (props: LinkTooltipProps) => {
    const [state, setState] = useState<State>({
        link: null,
        fixed: false
    });

    const eventHandler = useCallback((event: Event) => {
        if (event instanceof MouseEvent && event.target instanceof Element) {
            if (event.type === 'mouseout' && !state.fixed) {
                setState({fixed: false, link: null});
                return;
            }
            if (event.type === 'mouseover' && state.fixed) return;
            const ids = event.target.getAttribute("link-tooltip-id");
            if (!ids) return;
            const [id1, id2] = ids.split(" ");
            const link = props.nodesMap.get(id1)?.adjacent.find(link => link.target.id === id2 || link.source.id === id2);
            if (!link) return;
            if (event.type === 'mouseover') { // state is definitely not fixed due to the previous check
                setState({fixed: false, link});
            } else if (event.type === 'click') {
                setState({fixed: true, link});
            }
        }
    }, [props.nodesMap, state.fixed]);

    useEffect(() => {
        const documentObserver = new MutationObserver((linksMutations) => {
            linksMutations.forEach(mutation => {
                mutation.addedNodes.forEach(node => {
                    node.addEventListener('mouseover', eventHandler);
                    node.addEventListener('mouseout', eventHandler);
                    node.addEventListener('click', eventHandler);
                });
                // listeners get removed when element leave DOM
            });
        });
        const linksGroup = props.svgRef.current?.querySelector("g#links");
        if (linksGroup) {
            linksGroup.childNodes.forEach(node => {
                node.addEventListener('mouseover', eventHandler);
                node.addEventListener('mouseout', eventHandler);
                node.addEventListener('click', eventHandler);
            });
            documentObserver.observe(linksGroup, {
                childList: true,
                subtree: true
            });
        }
        const currentHandler = eventHandler;
        return () => {
            documentObserver.disconnect();
            const cleanupGroup = props.svgRef.current?.querySelector("g#links");
            if (cleanupGroup) {
                cleanupGroup.childNodes.forEach(node => {
                    node.removeEventListener('mouseover', currentHandler);
                    node.removeEventListener('mouseout', currentHandler);
                    node.removeEventListener('click', currentHandler);
                });
            }
        }
    }, [eventHandler, props.svgRef]);

    if (!props.show || !state.link) return null;

    return (
        <Island id="link_tooltip">
            <Header border>
                {`${state.link.source.name} & ${state.link.target.name}`}
                {state.fixed ?
                    <Button title="Help" icon={pinIcon}
                            onClick={() => setState({fixed: false, link: null})}/>
                    : null}
            </Header>
            <Content fade>
                <List
                    data={state.link.meta.map((meta, i) => ({...metaToItem(meta, props.meetingsMap), key: i}))}/>
            </Content>
        </Island>
    )
};