import React, {useState} from "react";
import Dialog from "@jetbrains/ring-ui/dist/dialog/dialog";
import {Content, Header} from "@jetbrains/ring-ui/dist/island/island";
import Panel from "@jetbrains/ring-ui/dist/panel/panel";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {PropsWithGraph} from "./utils/Props";
import {GraphNode} from "../model/Model";
import {NodeSearch} from "./NodeSearch";

export interface ConnectNodesDialogProps extends PropsWithGraph {
    readonly show: boolean,
    readonly onClose: () => void,
    readonly onConnect: (firstId: string, secondId: string) => void
}

export const ConnectNodesDialog = (props: ConnectNodesDialogProps) => {
    const [firstNode, setFirstNode] = useState<GraphNode | null>();
    const [secondNode, setSecondNode] = useState<GraphNode | null>();

    return (
        <Dialog
            label="Explan Dialog"
            show={props.show}
            onCloseAttempt={() => props.onClose()}
            trapFocus
            autoFocusFirst
            showCloseButton
        >
            <Header>Connect two nodes</Header>
            <Content>
                <NodeSearch onApplySuggestion={suggestion => setFirstNode(suggestion.node)}
                            placeholder={"First node"} graph={props.graph}/>
                <NodeSearch onApplySuggestion={suggestion => setSecondNode(suggestion.node)}
                            placeholder={"First node"} graph={props.graph}/>
            </Content>
            <Panel>
                <Button primary disabled={!firstNode && !secondNode} onClick={() => {
                    if (!firstNode || !secondNode) return;
                    props.onClose();
                    props.onConnect(firstNode.id, secondNode.id);
                    setFirstNode(null);
                    setSecondNode(null);
                }}>
                    Build connection
                </Button>
                <Button onClick={() => props.onClose()}>Cancel</Button>
            </Panel>
        </Dialog>
    );
}
