import frownIcon from "@jetbrains/icons/frown";
import ErrorMessage from "@jetbrains/ring-ui/dist/error-message/error-message";
import Link from "@jetbrains/ring-ui/dist/link/link";
import React, {PropsWithChildren} from "react";

interface ErrorBoundaryState {
    readonly hasError: boolean
    readonly errorMessage: string
    readonly errorInfo: string
}

export class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
    constructor(props: PropsWithChildren) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: "",
            errorInfo: ""
        };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({
            errorMessage: error.message,
            errorInfo: errorInfo.componentStack,
            hasError: true
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorMessage
                    icon={frownIcon}
                    code="An error occured"
                    message={this.state.errorMessage}
                    description={this.state.errorInfo}

                >
                    <Link href="/">
                        Go to the home page
                    </Link>
                    <br/>
                    <Link href="https://jetbrains.team/p/soccon/new-issue?status=open" target="_blank">
                        Report the problem
                    </Link>
                </ErrorMessage>
            );
        }

        return this.props.children;
    }
}