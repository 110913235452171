import * as d3 from "d3";
import {D3ZoomEvent} from "d3";
import {useCallback, useEffect, useRef} from "react";
import {GraphLink, GraphNode, LinkMetaType, NodeType} from "../model/Model";
import {ForceGraphProps} from "../components/ForceGraph";
import {useSvgDimensions} from "./useSvgDimensions";
import {D3Link, D3Node} from "../model/D3Wrappers";
import {forceBounds} from "../graph/forceBounds";

type D3Elements = {
    svg: d3.Selection<SVGSVGElement, any, any, any>;
    nodes: d3.Selection<SVGGElement, GraphNode, any, any>;
    labels: d3.Selection<SVGGElement, GraphNode, any, any>;
    links: d3.Selection<SVGGElement, GraphLink, any, any>;
    all: d3.Selection<SVGGElement, any, any, any>;
    simulation: d3.Simulation<GraphNode, GraphLink>;
}

export default function useD3Simulation() {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const elements = useRef<D3Elements | null>(null)
    const [w, h] = useSvgDimensions(svgRef);

    // FIXME: look into
    // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
    // https://legacy.reactjs.org/docs/hooks-faq.html?source=post_page-----eb7c15198780--------------------------------#how-can-i-measure-a-dom-node
    useEffect(() => {
        if (!svgRef.current) {
            console.error("React error: no svg ref!");
            throw new Error("React error: no svg ref!");
        }

        const svg = d3.select(svgRef.current);

        const all = svg.append('g');

        // important: links first so they are underneath nodes
        const links = all
            .append('g')
            .attr("id", "links")
            .selectAll<SVGGElement, GraphLink>("path");

        const nodes = all
            .append("g")
            .attr("id", "nodes")
            .selectAll<SVGGElement, GraphNode>("image");

        const labels = all
            .append("g")
            .attr("id", "labels")
            .selectAll<SVGGElement, GraphNode>("text");

        const simulation = d3
            .forceSimulation<GraphNode>([])
            .force("charge", d3
                .forceManyBody()
                .strength(-100)
                .distanceMin(1)
                .distanceMax(baseDistance * 5)
            )
            .force("collide", d3.forceCollide(node => node.radius))
            .on("tick", () => {
                if (!elements.current) return;

                elements.current.links
                    .attr("d", link => linkToPath(link));

                elements.current.nodes
                    .attr("x", node => node.x - node.radius / 2)
                    .attr("y", node => node.y - node.radius / 2);

                elements.current.labels
                    .attr("x", node => node.x)
                    .attr("y", node => node.y);
            });

        elements.current = {
            svg,
            nodes,
            labels,
            links,
            all,
            simulation
        };

        return () => {
            simulation.stop();
            all.remove();
        };
    }, []);

    useEffect(() => {
        if (!elements.current) return;
        const newBounds = bounds(w, h);
        // update simulation
        const simulation = elements.current.simulation;
        // FIXME: radius is multiplied by 2, because node center is off-center due to clip path
        simulation.force("bounds", forceBounds<GraphNode>(newBounds).radius(n => n.radius * 2));
        simulation.alpha(simulation.alpha() + 0.1).restart();
        // setup zoom
        elements.current?.svg.attr("viewBox", [-w / 2, -h / 2, w, h]);
        const zoom = d3
            .zoom<any, any>()
            .scaleExtent([1 / 4, 1])
            .translateExtent(newBounds)
            .on("zoom", (d3ZoomEvent: D3ZoomEvent<any, any>) => {
                if (!elements.current) return;
                elements.current.all.attr("transform", "scale(" + d3ZoomEvent.transform.k + ") translate(" + d3ZoomEvent.transform.x + " " + d3ZoomEvent.transform.y + ")");
            });
        elements.current.svg.call(zoom, d3.zoomIdentity.scale(0.75));
    }, [w, h]);

    const update = useCallback((props: ForceGraphProps) => { // , nodeFilter: (node: GraphNode) => boolean
        console.debug("svg graph update");
        if (!elements.current) return;
        elements.current.simulation.stop();

        elements.current.nodes = elements.current.nodes
            .data(props.graph.nodes, node => node.id)
            .join(enter => enter
                .append("image")
                .attr("xlink:href", node => getAvatarUri(node))
                .attr("height", d => d.radius)
                .attr("width", d => d.radius)
                .attr("x", d => d.x)
                .attr("y", d => d.y)
                .attr("clip-path", d => "circle(" + (d.radius / 2) + "px)")
                .attr("data-tooltip-content", node => node.id)
                .attr("data-tooltip-id", "nodeTooltip")
                // @ts-ignore
                .call(drag(elements.current.simulation))
            );

        const visibleLabels = props.labelsEnabled ? props.graph.nodes.filter(node => node.type !== NodeType.USER) : [];
        elements.current.labels = elements.current.labels
            .data(visibleLabels, node => node.id)
            .join(enter => enter.append("text")
                .attr('unselectable', 'on')
                .attr("dy", node => node.radius / 2 + 7) // FIXME: Magic number 7
                .style('user-select', 'none')
                .text(node => node.name));

        elements.current.links = elements.current.links
            .data(props.graph.links)
            .join("path")
            .attr("link-tooltip-id", link => `${link.source.id} ${link.target.id}`)
            .attr("marker-mid", link => `url(#${getMarker(link)})`); //.attr("data-tooltip-id", "linkTooltip");

        elements.current.simulation.nodes(props.graph.nodes);
        elements.current.simulation.force("link",
            d3
                .forceLink<GraphNode, GraphLink>(props.graph.links)
                .id(node => node.id)
                .distance(linkDistance)
        )
        elements.current.simulation.alpha(0.3).restart();
    }, [elements]);

    return {
        svgRef,
        update
    };
};

const drag = (simulation: d3.Simulation<GraphNode, GraphLink>) => {
    const dragstarted = (event: any, d: GraphNode) => {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    };

    const dragged = (event: any, d: GraphNode) => {
        d.fx = event.x;
        d.fy = event.y;
    };

    const dragended = (event: any, d: GraphNode) => {
        if (!event.active) simulation.alphaTarget(0);
        d.x = d.fx ?? d.x;
        d.y = d.fy ?? d.y;
        d.fx = null;
        d.fy = null;
    };

    return d3
        .drag<SVGGElement, GraphNode, unknown>()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
};

export const getAvatarUri = (node: GraphNode) => {
    switch (node.type) {
        case NodeType.USER: {
            const searchParams = new URLSearchParams();
            if (node.details && node.details.avatarId) {
                searchParams.set('avatarId', node.details.avatarId);
            } else if (node.details) { // tried to load avatar and failed
                return `/no_image.png`
            } else {
                searchParams.set('userId', node.id);
            }
            return `${process.env.REACT_APP_SERVER_URL}/api/avatar?${searchParams.toString()}`;
        }

        case NodeType.TEAM: {
            const searchParams = new URLSearchParams();
            searchParams.set('teamName', node.name);
            return `${process.env.REACT_APP_SERVER_URL}/api/avatar?${searchParams.toString()}`;
        }

        case NodeType.LOCATION:
            return `/geo.png`;
    }
}

const bounds = (w: number, h: number): [[number, number], [number, number]] => [[-2 * w, -2 * h], [2 * w, 2 * h]];

const midPoint = (from: D3Node, to: D3Node) =>
    `${(from.x + to.x) / 2},${(from.y + to.y) / 2}`

const linkToPath = (link: D3Link) =>
    `M${link.source.x},${link.source.y},${midPoint(link.source, link.target)},${link.target.x},${link.target.y}`;

const baseDistance = 600;

export const linkDistance = (link: GraphLink) => {
    let distance = baseDistance;
    if (link.source.type === NodeType.USER || link.target.type === NodeType.USER) {
        distance /= 2;
        distance -= Math.min(link.meta.length, 2.5) * 100;
    }
    if (link.source.type === NodeType.USER && link.target.type === NodeType.USER) {
        distance -= Math.min(link.meta.length, 2.5) * 100;
    }
    if (link.source.type === NodeType.TEAM) {
        distance += link.source.size / 10;
    }
    if (link.target.type === NodeType.TEAM) {
        distance += link.target.size / 10;
    }
    return link.source.radius + link.target.radius + distance;
}

export function getMarker(link: GraphLink) {
    let codir = false;
    let counter = false;
    link.meta.forEach(meta => {
        switch (meta.type) {
            case LinkMetaType.MANAGER:
            case LinkMetaType.PARENT:
                codir = true;
                return;
            // TODO: team lead case ~ LinkMetaType.MEMBER: meta.teamlead: boolean
            case LinkMetaType.REGANAM:
                counter = true;
        }
    });
    if (codir && counter) return "both";
    if (codir) return "codir";
    if (counter) return "counter";
    return "";
}
