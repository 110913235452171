import {useEffect, useReducer} from "react";
import axios, {AxiosError} from "axios";

type Action =
    | {
    readonly type: 'login'
}
    | {
    readonly type: 'logout'
}
    | {
    readonly type: 'started'
}
    | {
    readonly type: 'error'
    readonly error: AxiosError | null
}

enum Status { IDLE, AUTHED, IN_PROCESS}

type State = {
    readonly status: Status
    readonly error: AxiosError | null
}

type Reducer = (prevState: State, action: Action) => State

function reducer(_state: State, action: Action): State {
    switch (action.type) {
        case 'error':
            return {error: action.error, status: Status.IDLE};

        case 'login':
            return {error: null, status: Status.AUTHED};

        case 'started':
            return {error: null, status: Status.IN_PROCESS};

        case 'logout':
            return {error: null, status: Status.IDLE};

        default:
            throw new Error('Unsupported action');
    }
}

export default function useAuth(): {
    authed: boolean,
    logout: () => void,
    error: AxiosError | null
} {
    const [state, dispatch] = useReducer<Reducer>(reducer, {
        status: Status.IDLE,
        error: null
    })

    const logout = () => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/logout`).then(() => {
            dispatch({type: 'logout'});
        });
    }

    useEffect(() => {
        if (state.status === Status.AUTHED || state.status === Status.IN_PROCESS) return;
        const authCode = new URLSearchParams(window.location.search).get('code');
        if (authCode) {
            dispatch({type: 'started'});
            axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/code?code=${authCode}`).then(
                () => {
                    window.history.replaceState({}, document.title, window.location.pathname);
                    dispatch({type: 'login'});
                },
                (error: AxiosError) => {
                    window.history.replaceState({}, document.title, window.location.pathname);
                    dispatch({type: 'error', error});
                }
            );
            return;
        }
        axios.get<string>(`${process.env.REACT_APP_SERVER_URL}/auth/login`).then(
            () => dispatch({type: 'login'}), // already logged in
            (error: AxiosError) => {
                if (error.response?.status === 308) { // get redirected to Space OAuth server
                    window.location.replace(error.response.data as string)
                } else {
                    dispatch({type: 'error', error});
                }
            }
        );
    }, [state.status]);

    return {
        authed: state.status === Status.AUTHED,
        logout,
        error: state.error
    };
}