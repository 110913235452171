import React, {useCallback, useEffect, useState} from "react";
import QueryAssist from "@jetbrains/ring-ui/dist/query-assist/query-assist";
import {QueryAssistRequestParams} from "@jetbrains/ring-ui/dist/query-assist/query-assist";
import {QueryAssistResponse} from "@jetbrains/ring-ui/dist/query-assist/query-assist";
import {QueryAssistSuggestion} from "@jetbrains/ring-ui/dist/query-assist/query-assist__suggestions";
import {GraphNode, NodeType} from "../model/Model";
import {PropsWithGraph} from "./utils/Props";

export interface NodeSearchProps extends PropsWithGraph {
    readonly onApplySuggestion: (suggestion: NodeSuggestion) => void,
    readonly placeholder?: string,
    readonly hint?: string
}

export const NodeSearch = (props: NodeSearchProps) => {
    const [data, changeData] = useState(nodesAsItems(props.graph.nodes));

    useEffect(() => {
        changeData(nodesAsItems(props.graph.nodes));
    }, [props.graph]);

    const dataSource: (params: QueryAssistRequestParams) => QueryAssistResponse =
        useCallback(({query, caret}: QueryAssistRequestParams) => {
            let viableSuggestions: QueryAssistSuggestion[] = [];
            const lowercaseQuery = query.toLowerCase();
            // optimization to fix '[Violation] 'input' handler took XXXms'
            for (let i = 0; i < data.length && viableSuggestions.length <= 50; i++) {
                const matchingStart = data[i].option.toLowerCase().indexOf(lowercaseQuery);
                if (matchingStart !== -1) {
                    viableSuggestions.push({
                        ...data[i],
                        caret,
                        matchingStart,
                        matchingEnd: matchingStart + query.length,
                        completionStart: matchingStart - 1,
                        completionEnd: matchingStart + query.length
                    });
                }
            }

            return {
                query,
                caret,
                suggestions: viableSuggestions
            }
        }, [data]);

    return (
        <QueryAssist className="node_search" placeholder={props.placeholder} hint={props.hint}
                     glass={true} clear={true}  dataSource={dataSource}
                     onApplySuggestion={suggestion => props.onApplySuggestion(suggestion as NodeSuggestion)}
        />
    );
};

interface NodeSuggestion extends QueryAssistSuggestion {
    readonly node: GraphNode
}

const nodeTypeToGroup = (type: NodeType) => {
    switch (type) {
        case NodeType.LOCATION:
            return "Office Locations";
        case NodeType.TEAM:
            return "Teams";
        case NodeType.USER:
            return "People"
    }
}

const nodesAsItems: (nodes: GraphNode[]) => NodeSuggestion[] = (nodes: GraphNode[]) =>
    nodes.map(node => ({
        node,
        option: node.name,
        description: node.visible ? "+" : "-",
        group: nodeTypeToGroup(node.type)
    }))