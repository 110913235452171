import {SimulationNodeDatum} from "d3-force";
import {Force} from "d3";

function bound(coordinate: number, min: number, max: number) {
    return Math.min(max, Math.max(min, coordinate));
}

interface ForceBounds<NodeDatum extends SimulationNodeDatum> extends Force<NodeDatum, never> {
    readonly radius: (r: (n: NodeDatum) => number) => ForceBounds<NodeDatum>;
}

export function forceBounds<NodeDatum extends SimulationNodeDatum>(
    bounds: [[minx: number, miny: number], [maxx: number, maxy: number]],
    radius?: (n: NodeDatum) => number
): ForceBounds<NodeDatum> {
    let nodes: NodeDatum[] = [];

    force.initialize = (initNodes: NodeDatum[], random: () => number) => {
        nodes = initNodes;
    }
    force.radius_fun = radius ?? ((n: NodeDatum) => 1);
    force.bounds = bounds;

    force.radius = (r: (n: NodeDatum) => number) => {
        force.radius_fun = r;
        return force;
    }

    function force(a: number) {
        const n = nodes.length;
        const bounds = force.bounds;
        for (let i = 0; i < n; ++i) {
            const node = nodes[i];
            const r = force.radius_fun(node);

            if (node.x) node.x = bound(node.x, bounds[0][0] + r, bounds[1][0] - r);
            if (node.y) node.y = bound(node.y, bounds[0][1] + r, bounds[1][1] - r);
        }
    }

    return force;
}