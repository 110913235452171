import React from "react";
import Dropdown from "@jetbrains/ring-ui/dist/dropdown/dropdown";
import Button from "@jetbrains/ring-ui/dist/button/button";
import PopupMenu from "@jetbrains/ring-ui/dist/popup-menu/popup-menu";
import {ListProps} from "@jetbrains/ring-ui/dist/popup-menu/popup-menu";
import {DatePickerChange} from "@jetbrains/ring-ui/dist/date-picker/consts";
import DatePicker from "@jetbrains/ring-ui/dist/date-picker/date-picker";
import filtersIcon from '@jetbrains/icons/filters';
import {LinkMetaType, linkMetaTypesWithLabels, nodeTypesWithLabels} from "../model/Model";
import {GraphFiltersWithSetters} from "../hooks/useGraphFilters";

export interface FiltersProps {
    readonly filters: GraphFiltersWithSetters
}

const isMetaVisible = (types: LinkMetaType[], visibleMeta: Set<LinkMetaType>) => {
    let visible = true;
    types.forEach(meta => visible = visible && visibleMeta.has(meta));
    return visible;
}

export const Filters = (props: FiltersProps) => {
    let text = props.filters.pendingParticipantsThreshold.toString();

    return (
        <Dropdown anchor={<Button title="Filters" icon={filtersIcon}/>}>
            <PopupMenu data={
                [
                    {rgItemType: ListProps.Type.SEPARATOR, description: 'Links timeframe'},
                    {
                        rgItemType: ListProps.Type.CUSTOM,
                        // TODO: dates are off by one?
                        template: <DatePicker from={props.filters.dates.from} to={props.filters.dates.to} range
                                              minDate={"2023-09-04"} maxDate={"2023-09-24"} // FIXME: demo limitation
                                              onChange={(dateChange: DatePickerChange) => {
                                                  const from = dateChange.from ?? props.filters.dates.from;
                                                  const to = dateChange.to ?? props.filters.dates.to;
                                                  props.filters.changeDates({from, to});
                                              }}/>
                    },
                    {rgItemType: ListProps.Type.SEPARATOR, description: 'Meeting participants'},
                    {
                        rgItemType: ListProps.Type.CUSTOM,
                        template: <>
                            <input type={"range"} value={props.filters.pendingParticipantsThreshold}
                                   onChange={(change) => props.filters.changeParticipantsThreshold(parseInt(change.target.value))}
                            />
                            <span>{text}</span>
                        </>
                    },
                    {rgItemType: ListProps.Type.SEPARATOR, description: 'Node types'},
                    ...nodeTypesWithLabels
                        .map(({type, label}) => ({
                            rgItemType: ListProps.Type.ITEM,
                            label,
                            checkbox: props.filters.visibleNodeTypes.has(type),
                            onClick: () => props.filters.nodeTypeSwitch(type)
                        })),
                    {rgItemType: ListProps.Type.SEPARATOR, description: 'Link types'},
                    ...linkMetaTypesWithLabels
                        .map(({types, label}) => ({
                            rgItemType: ListProps.Type.ITEM,
                            label,
                            checkbox: isMetaVisible(types, props.filters.visibleLinkMeta),
                            onClick: () => props.filters.linkTypeSwitch(types)
                        }))
                ].map((item, index) => ({...item, key: index}))
            }/>
        </Dropdown>
    )
}
