import {useCallback, useReducer} from 'react'
import axios, {AxiosError} from "axios";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {DataLink, Meeting} from "../model/Responses";

type Action =
    | {
    readonly type: 'loading',
    abortController: AbortController
}
    | {
    readonly type: 'loaded',
    readonly meetingsData: Meeting[],
    readonly meetingLinksData: DataLink[]
}
    | {
    readonly type: 'error',
    readonly error: any
}

enum Status { IDLE, LOADING, READY}

type State = {
    readonly status: Status,
    readonly meetingsData: Meeting[] | null,
    readonly meetingLinksData: DataLink[] | null,
    readonly error: any,
    readonly alertKey: string | number | null,
    readonly abortController: AbortController | null
}

type Reducer = (prevState: State, action: Action) => State

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'loading': {
            if (state.alertKey) alertService.remove(state.alertKey);
            if (state.abortController) state.abortController.abort();
            return {
                ...state,
                error: null,
                alertKey: alertService.loadingMessage(`Loading meetings...`),
                status: Status.LOADING,
                abortController: action.abortController
            };
        }

        case 'error':
            if (state.alertKey) alertService.remove(state.alertKey);
            if (state.abortController) state.abortController.abort();
            alertService.error(`Failed to load meetings`, 5000);
            return {...state, error: action.error, alertKey: null, status: Status.IDLE};

        case 'loaded':
            if (state.alertKey) alertService.remove(state.alertKey);
            alertService.successMessage("Loaded meetings", 3000);
            return {
                meetingsData: action.meetingsData,
                meetingLinksData: action.meetingLinksData,
                error: null,
                alertKey: null,
                abortController: null,
                status: Status.READY,
            }

        default:
            throw new Error('Unsupported action');
    }
}

export type MeetingsData = {
    meetingsData: Meeting[],
    meetingLinksData: DataLink[]
}

export default function useMeetings(): {
    loading: boolean
    ready: boolean
    readonly meetingsData: Meeting[] | null
    readonly meetingLinksData: DataLink[] | null
    readonly error: any
    load: () => Promise<MeetingsData | null>
} {
    const [state, dispatch] = useReducer<Reducer>(reducer, {
        status: Status.IDLE,
        meetingsData: null,
        meetingLinksData: null,
        error: null,
        alertKey: null,
        abortController: null
    })

    const load = useCallback(() => {
        const abortController = new AbortController();
        dispatch({type: 'loading', abortController});
        const meetingsPromise: Promise<Meeting[] | null> = axios.get('/meetings', {
            signal: abortController.signal
        }).then(
            response => response.data,
            error => {
                if (error.code !== AxiosError.ERR_CANCELED) { // cancelled because another load was launched
                    console.debug(error);
                    dispatch({type: 'error', error: error});
                }
                return null;
            }
        );
        const linksPromise: Promise<DataLink[] | null> = axios.get('/meetings/links', {
            signal: abortController.signal
        }).then(
            response => response.data,
            (error: AxiosError) => {
                if (error.code !== AxiosError.ERR_CANCELED) { // cancelled because another load was launched
                    console.debug(error);
                    dispatch({type: 'error', error: error});
                }
                return null;
            }
        );
        return Promise.all([meetingsPromise, linksPromise]).then(([meetingsData,  meetingLinksData]) => {
            if (!meetingsData || !meetingLinksData)
                return null;
            dispatch({type: "loaded", meetingsData, meetingLinksData});
            return {meetingsData,  meetingLinksData};
            }
        ).catch(_ => {
            console.debug("Error in all");
            return null;
        });
    }, []);

    return {
        ...state,
        loading: state.status === Status.LOADING,
        ready: state.status === Status.READY,
        load
    };
}