import React, {useState} from "react";
import Dialog from "@jetbrains/ring-ui/dist/dialog/dialog";
import {Content, Header} from "@jetbrains/ring-ui/dist/island/island";
import Panel from "@jetbrains/ring-ui/dist/panel/panel";
import Button from "@jetbrains/ring-ui/dist/button/button";
import Markdown from "@jetbrains/ring-ui/dist/markdown/markdown";
import helpIcon from "@jetbrains/icons/help";

const helpMessageInMarkDown = `
This is a **demo** of the project currently in development at [ICTL](https://jetbrains.team/team?team=24lpC41LJeKP), aimed to visualise the JetBrains company structure.

What you should know:
1. To hide a node or show adjacent nodes, hover over it and click one of the buttons in the tooltip
2. Filter nodes or interactions via \`Filters\` at the top, change graph via actions in \`Magic wand\`
3. To include meetings data, go to \`Settings\` and check \`Meetings\` under \`Data\`. Currently, only meetings for Sep 4th-24th are included
4. Click on a link to pin its tooltip. Hover over a meeting to see its date (which can be wrong) and number of participants. Click on the pin icon in the tooltip to unpin it
5. Search in the top left, click on a node from the list will bring it into the graph
6. If everything goes terribly wrong, try clicking the update icon a the top to re-render graph. Or reload the page!

If you encounter any bugs or have suggestions, use the link in the bottom right of the page.
`

export const Help = () => {
    const [show, setShow] = useState(true);

    return (
        <>
            <Button title="Help" icon={helpIcon} onClick={() => setShow(true)}/>
            <Dialog
                id="help"
                label="Help Dialog"
                show={show}
                onCloseAttempt={() => setShow(false)}
                autoFocusFirst={false}>
                <Header>Welcome to Space Graph!</Header>
                <Content>
                    <Markdown>{helpMessageInMarkDown}</Markdown>
                </Content>
                <Panel>
                    <Button primary onClick={() => setShow(false)}>Okay, got it</Button>
                </Panel>
            </Dialog>
        </>);
}