import Footer from "@jetbrains/ring-ui/dist/footer/footer";

export const AppFooter = () => (
    <Footer
        className="stuff"
        left={[
            [
                {
                    url: 'https://jetbrains.team/p/soccon',
                    label: 'Space Graph',
                    target: '_blank'
                },
                ' by ICTL @ JetBrains'
            ]
        ]}
        center={[
            [{copyright: 2000, label: ' JetBrains'}],
            {
                url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                label: 'License agreement',
                title: 'read me!',
                target: '_blank'
            }
        ]}
        right={[
            {
                url: 'https://jetbrains.team/p/soccon/new-issue?status=open',
                label: 'Report an issue or leave a suggestion',
                target: '_blank'
            }
        ]
        }
    />);