import React from "react";
import Dropdown from "@jetbrains/ring-ui/dist/dropdown/dropdown";
import Button from "@jetbrains/ring-ui/dist/button/button";
import PopupMenu from "@jetbrains/ring-ui/dist/popup-menu/popup-menu";
import {ListProps} from "@jetbrains/ring-ui/dist/popup-menu/popup-menu";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import settingsIcon from '@jetbrains/icons/settings';
import useMeetings, {MeetingsData} from "../hooks/useMeetings";
import {UiSettings} from "../hooks/useUiSettings";

export interface SettingsProps {
    readonly uiSettings: UiSettings,
    readonly onMeetingsLoaded: (data: MeetingsData) => void
}

export const Settings = (props: SettingsProps) => {
    const {ready: loadedMeetings, loading: loadingMeetings, load: loadMeetings} = useMeetings();

    return (
        <Dropdown anchor={<Button title="Settings" icon={settingsIcon}/>}>
            <PopupMenu data={
                [
                    {rgItemType: ListProps.Type.SEPARATOR, description: "UI elements"},
                    {
                        rgItemType: ListProps.Type.ITEM,
                        label: "Node Labels",
                        checkbox: props.uiSettings.labelsEnabled,
                        onClick: () => props.uiSettings.labelsSwitch()
                    },
                    {
                        rgItemType: ListProps.Type.ITEM,
                        label: "Node Tooltip",
                        checkbox: props.uiSettings.nodeTooltipEnabled,
                        onClick: () => props.uiSettings.nodeTooltipSwitch()
                    },
                    {
                        rgItemType: ListProps.Type.ITEM,
                        label: "Link Tooltip",
                        checkbox: props.uiSettings.linkTooltipEnabled,
                        onClick: () => props.uiSettings.linkTooltipSwitch()
                    },
                    {rgItemType: ListProps.Type.SEPARATOR, description: "Data"},
                    {
                        rgItemType: ListProps.Type.ITEM,
                        label: "Meetings",
                        checkbox: loadedMeetings,
                        disabled: loadedMeetings || loadingMeetings,
                        onClick: () => {
                            if (!loadedMeetings)
                                loadMeetings().then(meetingsData => {
                                    // TODO: load meetings depending on selected dates
                                    if (meetingsData) {
                                        props.onMeetingsLoaded(meetingsData)
                                    } else {
                                        alertService.message("Meetings already loaded", 3000);
                                    }
                                })
                        }
                    },
                    {
                        rgItemType: ListProps.Type.ITEM,
                        label: "Code Reviews",
                        checkbox: false,
                        disabled: true
                    }
                ].map((item, index) => ({...item, key: index}))}/>
        </Dropdown>
    )
}