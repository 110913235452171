import React, {MutableRefObject, useEffect} from 'react';
import {Graph} from "../model/Model";
import {PropsWithGraph} from "./utils/Props";
import useD3Simulation from "../hooks/useD3Simulation";

export interface ForceGraphProps extends PropsWithGraph {
    readonly graph: Graph,
    readonly labelsEnabled: boolean,
    readonly linkTooltipSvgRef: MutableRefObject<SVGSVGElement | null>
}

export const ForceGraph = (props: ForceGraphProps) => {
    const {svgRef, update} = useD3Simulation();

    useEffect(() => update(props), [props, props.graph, update])

    return (
        <svg className="Graph" ref={(svg) => {
            svgRef.current = svg;
            props.linkTooltipSvgRef.current = svg;
        }}>
            <defs>
                <marker markerUnits="userSpaceOnUse" id='codir' orient="auto" markerWidth='17' markerHeight='17'
                        refX='-5' refY='8'>
                    <path d='M1,1 V15 L16,8 Z' fill="#999"/>
                </marker>
                <marker markerUnits="userSpaceOnUse" id='counter' orient="auto" markerWidth='17' markerHeight='17'
                        refX='20' refY='8'>
                    <path d='M16,1 V15 L1,8 Z' fill="#999"/>
                </marker>
                <marker markerUnits="userSpaceOnUse" id='both' orient="auto" markerWidth='40' markerHeight='17'
                        refX='20' refY='8'>
                    <path d='M25,1 V15 L40,8 Z' fill="#999"/>
                    <path d='M16,1 V15 L1,8 Z' fill="#999"/>
                </marker>
            </defs>
        </svg>
    );
}
