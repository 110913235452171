import React, {useEffect} from "react";
import {Tooltip} from "react-tooltip";
import Button from "@jetbrains/ring-ui/dist/button/button";
import LoaderInline from "@jetbrains/ring-ui/dist/loader-inline/loader-inline";
import {UserCard} from "@jetbrains/ring-ui/dist/user-card/user-card";
import ButtonSet from "@jetbrains/ring-ui/dist/button-set/button-set";
import useFetchData from "../hooks/useFetchData";
import {UserDetails} from "../model/Responses";
import {GraphNode, NodeType} from "../model/Model";
import {PropsWithGraph} from "./utils/Props";
import {getAvatarUri} from "../hooks/useD3Simulation";

interface NodeCardProps extends PropsWithGraph {
    readonly content: string | null,
    readonly activeAnchor: HTMLElement | null,
    readonly onMakeVisible: (id: string) => void,
    readonly onHide: (id: string) => void,
    readonly onHideNonAdjacent: (id: string) => void
}

const buildCard = (node: GraphNode) => {
    switch (node.type) {
        case NodeType.TEAM:
            return {
                name: node.name,
                login: `${node.size} members`,
                avatarUrl: getAvatarUri(node),
                href: `${process.env.REACT_APP_SPACE_URL}/team?team=${node.id}`
            };
        case NodeType.LOCATION:
            return {
                name: node.name,
                login: "",
                avatarUrl: `/geo.png`,
                href: `${process.env.REACT_APP_SPACE_URL}/team?location=${node.id}`
            }
        case NodeType.USER:
            if (!node.details) {
                return {
                    name: node.name,
                    login: "",
                    avatarUrl: `/no_image.png`,
                    href: process.env.REACT_APP_SPACE_URL
                };
            }
            const rolesString = node.details.roles.map(role => `${role.name} in ${role.team} since ${role.since}`).join("; ")
            return {
                name: node.name,
                login: rolesString,
                avatarUrl: getAvatarUri(node),
                href: `${process.env.REACT_APP_SPACE_URL}/m/${node.details.username}`,
                details: "some details"
            };
    }
}

const NodeCard = (props: NodeCardProps) => {
    const node = props.content ? props.graph.nodesMap.get(props.content) : undefined;

    const {
        loading,
        error,
        load
    } = useFetchData<UserDetails>(node ? `/user/${node.id}` : "/user");

    useEffect(() => {
        if (node && !node.details && node.type === NodeType.USER)
            load().then(newDetails => {
                if (newDetails) node.details = newDetails;
            })
    }, [load, node]);

    useEffect(() => {
        if (error) throw error;
    }, [error]);

    return (
        <>
            {loading || !node ? <LoaderInline/> : <UserCard user={buildCard(node)}/>}
            <br/>
            <ButtonSet>
                <Button onClick={() => {
                    if (props.content) props.onHide(props.content)
                }}>
                    Hide this
                </Button>
                <Button onClick={() => {
                    if (props.content) props.onMakeVisible(props.content);
                }}>
                    Show adjacent
                </Button>
                <Button onClick={() => {
                    if (props.content) props.onHideNonAdjacent(props.content);
                }}>
                    Hide non-adjacent
                </Button>
            </ButtonSet>
        </>
    );
}

export interface NodeTooltipProps extends Omit<NodeCardProps, 'content' | 'activeAnchor'> {
    readonly show?: boolean,
    readonly onMakeVisible: (id: string) => void
}

export const NodeTooltip = (props: NodeTooltipProps) => {
    if (!props.show) return null;
    return (
        <Tooltip id="nodeTooltip" clickable={true} style={{backgroundColor: "#CDCDCD", color: "#000000", width: 420}}
                 render={
                     ({content, activeAnchor}) => (
                         <NodeCard {...props} content={content} activeAnchor={activeAnchor}/>
                     )
                 }
        />
    );
}
