import {useCallback, useReducer} from 'react'
import axios, {AxiosError} from "axios";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

type Action<T> =
    | {
    readonly type: 'loading'
}
    | {
    readonly type: 'error'
    readonly error: any
}
    | {
    readonly type: 'success'
    readonly data: T
}

enum Status { IDLE, LOADING, READY}

type State<T> = {
    readonly data: T | null
    readonly status: Status
    readonly error: any
}

type Reducer<T> = (prevState: State<T>, action: Action<T>) => State<T>

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
        case 'loading':
            return {...state, error: null, status: Status.LOADING};

        case 'error':
            return {...state, error: action.error, status: Status.IDLE};

        case 'success':
            return {
                data: action.data,
                error: null,
                status: Status.READY
            };

        default:
            throw new Error('Unsupported action');
    }
}

export default function useFetchData<T>(
    endpoint: string,
    name?: string
): {
    loading: boolean
    ready: boolean
    data: T | null
    readonly error: any
    load: (signal?: AbortSignal) => Promise<T | null>
} {
    const [state, dispatch] = useReducer<Reducer<T>>(reducer, {
        status: Status.IDLE,
        data: null,
        error: null,
    })

    const load = useCallback(async (signal?: AbortSignal) => {
        dispatch({type: 'loading'});
        const key = name ? alertService.loadingMessage(`Loading ${name}...`) : undefined;
        try {
            const response = await axios.get<T>(endpoint, {
                signal: signal
            });
            alertService.remove(key);
            if (name) alertService.successMessage(`Loaded ${name}`, 5000);
            const newData: T = response.data;
            dispatch({
                type: 'success',
                data: newData,
            });
            return newData;
        } catch (e) {
            if (e instanceof AxiosError && e.code === AxiosError.ERR_CANCELED) {
                // got cancelled
                return Promise.resolve(null);
            }
            if (name) alertService.error(`Failed to load ${name}`);
            dispatch({
                type: 'error',
                error: e,
            });
            return Promise.reject(e);
        }
    }, [endpoint, name]);

    return {
        ...state,
        loading: state.status === Status.LOADING,
        ready: state.status === Status.READY,
        load
    };
}