import {DataNode, LinkMeta, UserDetails} from "./Responses";
import {D3Link, D3Node} from "./D3Wrappers";

export type Graph = {
    nodes: Array<GraphNode>;
    links: Array<GraphLink>;
    nodesMap: Map<string, GraphNode>;
}

export enum NodeType {
    USER = "user",
    TEAM = "team",
    LOCATION = "loc"
}

export const nodeTypesWithLabels = [
    {type: NodeType.USER, label: "Profiles"},
    {type: NodeType.TEAM, label: "Teams"},
    {type: NodeType.LOCATION, label: "Locations"}
];

export enum LinkMetaType {
    MEETING = "meet",
    LOCATED = "located",
    MEMBER = "member",
    MANAGER = "manager",
    REGANAM = "reganam",
    PARENT = "parent"
    // CODE_REVIEW = "corev"
}

export const linkMetaTypesWithLabels = [
    {
        types: [LinkMetaType.PARENT, LinkMetaType.LOCATED, LinkMetaType.MANAGER, LinkMetaType.REGANAM, LinkMetaType.MEMBER],
        label: "Structure"
    },
    {types: [LinkMetaType.MEETING], label: "Meetings"}
    // {types: [LinkMetaType.CODE_REVIEW], label: "Code Reviews"}
];

export type GraphNode = D3Node & DataNode & {
    visible: boolean;
    adjacent: GraphLink[];
    radius: number;
    details?: UserDetails;
}

export type GraphLink = D3Link & {
    meta: LinkMeta[];
}
